//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-288:_2704,_336,_4096,_9412,_6960,_696,_7564,_6632;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-288')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-288', "_2704,_336,_4096,_9412,_6960,_696,_7564,_6632");
        }
      }catch (ex) {
        console.error(ex);
      }